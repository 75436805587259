<template>
  <div id="HeroCarousel">
    <div
      v-for="(heroCarouselData, index) in heroCarouselData"
      :key="'heroCarouselData' + index"
      data-aos="fade-up"
      data-aos-delay="300"
    >
      <div class="carousel-container">
        <b-carousel id="carousel-1" :interval="4000" indicators>
          <b-carousel-slide
            v-for="(carouselImages, index) in heroCarouselData.carouselImages"
            :key="'carouselImages' + index"
          >
            <template v-slot:img>
              <img
                :src="require('@/assets/images/' + carouselImages)"
                alt="image slot"
                class="d-block mx-auto"
                max-width="100%"
                max-height="460px"
              />
            </template>
          </b-carousel-slide>
        </b-carousel>
        <div class="title-overlay">
          <div class="title" v-html="heroCarouselData.title"></div>
          <div
            v-if="heroCarouselData.subTitle"
            class="sub-title"
            v-html="heroCarouselData.subTitle"
          ></div>
          <div v-if="heroCarouselData.soldOut" class="soldOut">
            currently sold&nbsp;out for&nbsp;2023
          </div>
        </div>
      </div>
      <div class="bottom-links">
        <div
          v-for="(bottomLinks, index) in heroCarouselData.bottomLinks"
          :key="'bottom-links' + index"
          class="bottom-links__item"
          data-aos="fade-up"
          :data-aos-delay="100 + index * 200"
        >
          <div
            v-if="bottomLinks.link == 'modal'"
            class="bottom-links__item-container"
            @click="modalShow = !modalShow"
          >
            <svg width="16" height="14" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-if="bottomLinks.icon == 'gallery'" v-bind:class="'bottom-links__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.504 14a.763.763 0 00.598-.176.817.817 0 00.28-.562l.493-9.246a.684.684 0 00-.193-.563.884.884 0 00-.58-.281l-2.918-.14-.176-1.9a.722.722 0 00-.264-.474A.69.69 0 0011.2.5L.793 1.344a.75.75 0 00-.51.263.638.638 0 00-.158.51l.738 8.297a.723.723 0 00.264.475.69.69 0 00.545.158l.527-.035-.07 1.617c-.024.234.04.422.193.562.153.141.346.223.58.246L14.504 14zM1.742 8.973L1.18 2.715V2.68c0-.211.117-.317.351-.317l9.176-.773c.188 0 .305.094.352.281v.035h.035l.07 1.09-7.7-.387a.786.786 0 00-.58.229.89.89 0 00-.263.545l-.246 4.71-.633.88zm12.621 2.707l-1.652-1.934-.95-1.16c-.07-.117-.198-.176-.386-.176-.14 0-.27.047-.387.14l-.597.423c-.141.07-.246.105-.317.105a.477.477 0 01-.351-.14l-.106-.106-1.512-1.723a.57.57 0 00-.492-.246.675.675 0 00-.492.211l-3.586 3.832-.21.246v-.246l.245-4.5.106-2.215v-.035c.047-.21.187-.316.422-.316l7.172.316 1.02.07 2.038.106c.258 0 .387.117.387.352v.035l-.352 6.96zm-2.25-4.22c.328 0 .616-.122.862-.368a1.18 1.18 0 00.369-.862 1.21 1.21 0 00-.352-.86 1.164 1.164 0 00-.879-.37 1.21 1.21 0 00-.861.352c-.246.234-.37.527-.37.878 0 .352.118.645.352.88.235.234.528.35.88.35z" fill-rule="nonzero"/></svg>
            {{ bottomLinks.title }}
          </div>
          <a
            v-else-if="bottomLinks.link == 'video'"
            class="bottom-links__item-container"
            v-show="bottomLinks.videoActive"
            @click="
              (modalTwoShow = !modalTwoShow), (videoID = bottomLinks.videoID)
            "
          >
            <svg width="8" height="11" viewBox="0 0 8 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-if="bottomLinks.icon == 'video'" v-bind:class="'bottom-links__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 10.75l8-5-8-5z" fill-rule="nonzero"/></svg>
            {{ bottomLinks.title }}
          </a>
          <!-- <button
            v-else-if="bottomLinks.link == '#'"
            class="bottom-links__item-container-buy"
            :data-fevo-offer-id="bottomLinks.fevo"
          >
            <svg width="9" height="16" viewBox="0 0 9 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-if="bottomLinks.icon == 'buy'" v-bind:class="'bottom-links__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.88 16v-1.92c.934-.185 1.67-.526 2.207-1.024.609-.555.913-1.266.913-2.133 0-.797-.212-1.472-.637-2.027-.354-.455-.87-.846-1.55-1.173-.495-.242-1.153-.47-1.973-.683-.977-.256-1.663-.533-2.06-.832-.396-.299-.594-.661-.594-1.088 0-.498.213-.9.637-1.205.425-.306 1.005-.459 1.74-.459 1.444 0 2.187.626 2.23 1.877h1.952c-.028-.796-.276-1.493-.743-2.09-.51-.64-1.217-1.067-2.122-1.28V0H3.226v1.92c-.891.185-1.613.548-2.165 1.088A2.85 2.85 0 00.17 5.12c0 .967.382 1.764 1.146 2.39.665.54 1.663.966 2.993 1.28 1.005.241 1.72.568 2.144.98.34.314.51.712.51 1.195 0 .399-.156.74-.468 1.024-.424.37-1.068.555-1.931.555-.864 0-1.522-.185-1.974-.555-.397-.313-.609-.753-.637-1.322H0c.057.938.396 1.706 1.019 2.304.566.54 1.302.91 2.207 1.109V16H5.88z" fill-rule="nonzero"/></svg>
            {{ bottomLinks.title }}
          </button> -->
          <a
            v-else-if="bottomLinks.link == '#'"
            href="#"
            v-scroll-to="'#selectDateAnchor'"
            class="bottom-links__item-container-buy"
          >
            <svg width="9" height="16" viewBox="0 0 9 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-if="bottomLinks.icon == 'buy'" v-bind:class="'bottom-links__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.88 16v-1.92c.934-.185 1.67-.526 2.207-1.024.609-.555.913-1.266.913-2.133 0-.797-.212-1.472-.637-2.027-.354-.455-.87-.846-1.55-1.173-.495-.242-1.153-.47-1.973-.683-.977-.256-1.663-.533-2.06-.832-.396-.299-.594-.661-.594-1.088 0-.498.213-.9.637-1.205.425-.306 1.005-.459 1.74-.459 1.444 0 2.187.626 2.23 1.877h1.952c-.028-.796-.276-1.493-.743-2.09-.51-.64-1.217-1.067-2.122-1.28V0H3.226v1.92c-.891.185-1.613.548-2.165 1.088A2.85 2.85 0 00.17 5.12c0 .967.382 1.764 1.146 2.39.665.54 1.663.966 2.993 1.28 1.005.241 1.72.568 2.144.98.34.314.51.712.51 1.195 0 .399-.156.74-.468 1.024-.424.37-1.068.555-1.931.555-.864 0-1.522-.185-1.974-.555-.397-.313-.609-.753-.637-1.322H0c.057.938.396 1.706 1.019 2.304.566.54 1.302.91 2.207 1.109V16H5.88z" fill-rule="nonzero"/></svg>
            {{ bottomLinks.title }}
          </a>
          <!-- <div
            v-else-if="bottomLinks.link == '#'"
            class="bottom-links__item-container-buy"
            :data-fevo-offer-id="bottomLinks.offerId"
            is="fevo-offer-button"
          >
            <svg width="9" height="16" viewBox="0 0 9 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-if="bottomLinks.icon == 'buy'" v-bind:class="'bottom-links__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.88 16v-1.92c.934-.185 1.67-.526 2.207-1.024.609-.555.913-1.266.913-2.133 0-.797-.212-1.472-.637-2.027-.354-.455-.87-.846-1.55-1.173-.495-.242-1.153-.47-1.973-.683-.977-.256-1.663-.533-2.06-.832-.396-.299-.594-.661-.594-1.088 0-.498.213-.9.637-1.205.425-.306 1.005-.459 1.74-.459 1.444 0 2.187.626 2.23 1.877h1.952c-.028-.796-.276-1.493-.743-2.09-.51-.64-1.217-1.067-2.122-1.28V0H3.226v1.92c-.891.185-1.613.548-2.165 1.088A2.85 2.85 0 00.17 5.12c0 .967.382 1.764 1.146 2.39.665.54 1.663.966 2.993 1.28 1.005.241 1.72.568 2.144.98.34.314.51.712.51 1.195 0 .399-.156.74-.468 1.024-.424.37-1.068.555-1.931.555-.864 0-1.522-.185-1.974-.555-.397-.313-.609-.753-.637-1.322H0c.057.938.396 1.706 1.019 2.304.566.54 1.302.91 2.207 1.109V16H5.88z" fill-rule="nonzero"/></svg>
            {{ bottomLinks.title }}
          </div> -->
          <button
            v-else-if="bottomLinks.link == 'appointment'"
            class="bottom-links__item-container"
            @click="
              (appointmentModal = !appointmentModal),
                buyFloodlight('DC-10973038/butto0/kpi_u00-+standard')
            "
          >
            <svg width="26" height="27" viewBox="0 0 26 27" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-if="bottomLinks.icon == 'appointment'" v-bind:class="'bottom-links__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill-rule="nonzero" fill="none"><path d="M19.568 26.92c.313 0 .58-.11.8-.331.222-.221.332-.488.332-.8V21.73c0-.313-.11-.58-.331-.8a1.09 1.09 0 00-.8-.331c-1.51 0-2.871-.212-4.085-.635a1.469 1.469 0 00-.635-.028.92.92 0 00-.552.304l-2.54 2.539a17.905 17.905 0 01-4.388-3.23 17.905 17.905 0 01-3.229-4.388l2.54-2.539a.92.92 0 00.303-.552c.037-.22.027-.432-.028-.635-.423-1.214-.635-2.576-.635-4.084 0-.313-.11-.58-.33-.8a1.09 1.09 0 00-.801-.332H1.132c-.313 0-.58.11-.8.331a1.09 1.09 0 00-.332.8c0 2.65.515 5.19 1.546 7.618a19.49 19.49 0 004.195 6.21 19.49 19.49 0 006.21 4.195 19.31 19.31 0 007.617 1.546z"/><path stroke="#BBB" stroke-width="1.656" d="M14.4 12.52l3.3-3.245h6.6V1.72h-9.9z"/></g></svg>
            Book an Appointment
          </button>
          <a
            v-else
            class="bottom-links__item-container"
            :href="bottomLinks.link"
          >
            <svg width="10" height="15" viewBox="0 0 10 15" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-if="bottomLinks.icon == 'map'" v-bind:class="'bottom-links__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.571 14.143c.429 0 .84-.241 1.027-.634l3.25-6.91c.232-.492.295-1.054.295-1.599A4.569 4.569 0 004.57.429 4.569 4.569 0 000 5c0 .545.063 1.107.295 1.598l3.259 6.91c.178.394.589.635 1.017.635zm0-6.857A2.29 2.29 0 012.286 5 2.29 2.29 0 014.57 2.714 2.29 2.29 0 016.857 5a2.29 2.29 0 01-2.286 2.286z" fill-rule="nonzero"/></svg>
            <svg width="15" height="14" viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-else-if="bottomLinks.icon == 'seat'" v-bind:class="'bottom-links__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11.053 7.747V2.103c0-.385-.142-.72-.425-1.003A1.372 1.372 0 009.625.675h-4.25c-.385 0-.72.142-1.003.425a1.372 1.372 0 00-.425 1.003v5.644h7.106zm-8.5 0V5.622H.428v2.125h2.125zm12.019 0V5.622h-2.125v2.125h2.125zM3.947 13.425V11.3h7.106v2.125h2.125v-4.25H1.822v4.25h2.125z" fill="#BBB" fill-rule="nonzero"/></svg>
            {{ bottomLinks.title }}
          </a>
        </div>
      </div>
      <b-modal id="modal-2" v-model="modalShow">
        <div class="modal-carousel-container">
          <div class="modal__close" @click="modalShow = !modalShow">
            <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.019 16a.947.947 0 00.695-.286.947.947 0 00.286-.695c0-.274-.1-.501-.298-.684L9.392 8l6.31-6.335A.888.888 0 0016 .98a.947.947 0 00-.286-.695A.947.947 0 0015.02 0c-.274 0-.501.1-.684.298L8 6.608 1.665.299A.888.888 0 00.98 0a.947.947 0 00-.695.286A.947.947 0 000 .98c0 .274.1.501.298.684L6.608 8l-6.31 6.335A.888.888 0 000 15.02c0 .273.095.505.286.695.19.19.422.286.695.286.274 0 .501-.1.684-.298L8 9.366l6.335 6.336c.183.199.41.298.684.298z" fill="#FFF" fill-rule="nonzero"/></svg>
          </div>
          <b-carousel id="carousel-2" :interval="4000" controls indicators>
            <b-carousel-slide
              v-for="(
                carousel2Images, index
              ) in heroCarouselData.carouselImages"
              :key="'carousel2Images' + index"
            >
              <template v-slot:img>
                <img
                  :src="require('@/assets/images/' + carousel2Images)"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </b-modal>
      <b-modal id="modal-2" v-model="modalTwoShow">
        <div class="modal-carousel-container">
          <div class="modal__close" @click="modalTwoShow = !modalTwoShow">
            <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.019 16a.947.947 0 00.695-.286.947.947 0 00.286-.695c0-.274-.1-.501-.298-.684L9.392 8l6.31-6.335A.888.888 0 0016 .98a.947.947 0 00-.286-.695A.947.947 0 0015.02 0c-.274 0-.501.1-.684.298L8 6.608 1.665.299A.888.888 0 00.98 0a.947.947 0 00-.695.286A.947.947 0 000 .98c0 .274.1.501.298.684L6.608 8l-6.31 6.335A.888.888 0 000 15.02c0 .273.095.505.286.695.19.19.422.286.695.286.274 0 .501-.1.684-.298L8 9.366l6.335 6.336c.183.199.41.298.684.298z" fill="#FFF" fill-rule="nonzero"/></svg>
          </div>
          <vueVimeoPlayer
            @ready="onReady"
            ref="player"
            :video-id="videoID"
            autoplay="true"
            background="true"
          >
          </vueVimeoPlayer>
        </div>
      </b-modal>
      <b-modal id="modal-5" v-model="appointmentModal" hide-footer>
        <div class="modal__close" @click="appointmentModal = !appointmentModal">
          <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.019 16a.947.947 0 00.695-.286.947.947 0 00.286-.695c0-.274-.1-.501-.298-.684L9.392 8l6.31-6.335A.888.888 0 0016 .98a.947.947 0 00-.286-.695A.947.947 0 0015.02 0c-.274 0-.501.1-.684.298L8 6.608 1.665.299A.888.888 0 00.98 0a.947.947 0 00-.695.286A.947.947 0 000 .98c0 .274.1.501.298.684L6.608 8l-6.31 6.335A.888.888 0 000 15.02c0 .273.095.505.286.695.19.19.422.286.695.286.274 0 .501-.1.684-.298L8 9.366l6.335 6.336c.183.199.41.298.684.298z" fill="#FFF" fill-rule="nonzero"/></svg>
        </div>
        <iframe
          class="privateFrame"
          src="https://app.acuityscheduling.com/schedule.php?owner=19209178&amp;calendarID=3713234&field:8429482=Website_Private_Appointment"
          title="Schedule Appointment"
        ></iframe>
      </b-modal>
    </div>
    <!-- <div class="gradient"></div> -->
  </div>
</template>

<script>
import { vueVimeoPlayer } from "vue-vimeo-player";

export default {
  name: "HeroCarousel",
  components: {
    vueVimeoPlayer,
  },
  props: {
    heroCarouselData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modalShow: false,
      modalTwoShow: false,
      playerReady: false,
      videoID: "",
      videoOptions: {
        background: true,
        muted: false,
      },
      appointmentModal: false,
    };
  },
  methods: {
    onReady() {
      this.playerReady = true;
    },
    buyFloodlight(id) {
      window.gtagClick(id);
    },
  },
};
</script>

<style lang="scss" scoped>
#HeroCarousel {
  .carousel-container {
    position: relative;
  }

  .title-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(
      180deg,
      rgba(0, 40, 140, 0.1) 0%,
      rgba(6, 27, 81, 0.7) 99%
    );
    font-family: "interstate", sans-serif;
    font-size: 42px;
    letter-spacing: 0;
    line-height: 43.2px;
    color: #ffffff;
    @media (max-width: $md) {
      font-size: 30px;
      line-height: 40px;
    }

    .title {
      font-weight: 700;
      font-style: normal;
      text-transform: uppercase;
    }
    .sub-title {
      font-weight: 400;
      // font-style: italic;
    }
    .soldOut {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 1;
      text-transform: uppercase;
      text-align: center;
      padding: 15px 50px;
      max-width: 85%;
      background: rgba(#ffffff, 0.3);
      border-radius: 5px;
      margin-top: 50px;
      @media (max-width: $md) {
        margin-top: 30px;
      }
      @media (max-width: $sm) {
        margin-top: 5px;
        font-size: 14px;
        padding: 10px 35px;
      }
    }
  }
  .bottom-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-transform: uppercase;
    font-family: "interstate", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 11px;
    background: #ffffff;
    color: $text-black;
    letter-spacing: 0;
    width: 100vw;
    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      width: 18%;
      border-left: 1px solid #e1e1e1;
      cursor: pointer;
      svg {
        outline: none;
      }
      @media (max-width: $lg) {
        flex: 1;
        width: auto;
        height: 71px;
        flex-direction: column;
      }
      &:last-child {
        border-right: 1px solid #e1e1e1;
      }
      &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $text-black;
        text-decoration: none;
        outline: none;
        height: 100%;
        width: 100%;
        transition: color 0.3s ease;
        @media (max-width: 650px) {
          width: 90%;
          text-align: center;
        }
        &:hover {
          color: $cta-blue;
        }
        &:hover svg {
          fill: $cta-blue;
          path {
            fill: $cta-blue;
          }
          polygon {
            fill: $cta-blue;
          }
        }
      }
      &-container-buy {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $cta-blue;
        text-decoration: none;
        outline: none;
        height: 100%;
        width: 100%;
        transition: color 0.3s ease;
        @media (max-width: 650px) {
          width: 90%;
          text-align: center;
        }
        svg {
          path {
            fill: $cta-blue;
          }
        }
        &:hover {
          color: #3572b1;
        }
        &:hover svg {
          fill: #3572b1;
          path {
            fill: #3572b1;
          }
        }
      }
      button {
        background: #ffffff;
        border: none;
        text-transform: uppercase;
        outline: none;
      }
    }
    &__icon {
      fill: #bbbbbb;
      height: 11px;
      width: 9px;
      margin-right: 9px;
      margin-top: -1px;
      // transition: fill 0.3s ease;
      path {
        fill: #bbbbbb;
        transition: fill 0.3s ease;
      }
      polygon {
        fill: #bbbbbb;
        transition: fill 0.3s ease;
      }
      @media (max-width: 650px) {
        display: none;
      }
    }
  }
  .gradient {
    width: 100%;
    height: 17px;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}
</style>

<style lang="scss">
#HeroCarousel {
  width: 100vw;

  /* Bootstrap Overrides */
  #carousel-1 {
    max-height: 460px;
    overflow: hidden;
  }
  .carousel-inner img {
    display: block;
    height: auto;
    max-height: 460px;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
  .carousel-item {
    position: relative;
    height: 100%;
    width: 100%;
    transition: all 0.6s cubic-bezier(0.93, 0.05, 0.05, 0.93);
  }
  .carousel-control-prev {
    z-index: 3 !important;
  }

  .carousel-control-next-icon {
    background-image: url("~@/assets/icons/home/icon_gallery-arrow.svg");
  }

  .carousel-control-prev-icon {
    background-image: url("~@/assets/icons/home/icon_gallery-arrow.svg");
    transform: rotate(180deg);
  }

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
    @media (max-width: $md) {
      display: flex;
      align-items: flex-end;
      bottom: 18px;
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 27px;
    height: 49px;
  }

  .carousel-control-prev {
    left: -2%;
  }

  .carousel-control-next {
    right: -2%;
  }

  .carousel-indicators {
    bottom: 22px;
    @media (max-width: $md) {
      bottom: 25px;
    }
    @media (max-width: $sm) {
      bottom: 18px;
    }
  }

  .carousel-indicators li {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 8px;
    opacity: 1;
    background-color: transparent;
    border: 1px solid #fff;
    outline: none;
    @media (max-width: $md) {
      width: 4px;
      height: 4px;
    }
  }

  .carousel-indicators li.active {
    background-color: #fff;
  }
}
// gallery modal
#modal-2 {
  background-image: linear-gradient(
    269deg,
    rgba(0, 40, 140, 0.7) 1%,
    rgba(0, 10, 35, 0.7) 100%
  );
  .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 28px 0;
    width: 75vw;
    max-width: 90vw;
  }
  .modal-content {
    border-radius: 5px;
    background: #ffffff;
    border: 0;
  }
  .modal-body {
    padding: 0;
    width: 100%;
  }
  .modal-carousel-container {
    position: relative;
    width: 100%;
  }
  .modal__close {
    position: absolute;
    top: -10px;
    right: -80px;
    width: 58px;
    height: 58px;
    border: 1px solid #ffffff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 9;
    @media (max-width: $md) {
      height: 48px;
      width: 48px;
      right: -65px;
    }
    @media (max-width: $sm) {
      height: 32px;
      width: 32px;
      right: -48px;
    }
    @media (max-width: 450px) {
      height: 26px;
      width: 26px;
      right: -38px;
    }
    svg {
      outline: none;
      @media (max-width: $md) {
        width: 12px;
        height: 12px;
      }
      @media (max-width: $sm) {
        width: 10px;
        height: 10px;
      }
      @media (max-width: 450px) {
        width: 8px;
        height: 8px;
      }
    }
  }
  .modal-header {
    height: 10px;
    padding: 0;
    button {
      display: none;
    }
  }
  .modal-footer {
    height: 10px;
    padding: 0;
  }
  .btn {
    display: none;
  }
  #carousel-2 {
    width: 100%;
    carousel-control-prev {
      z-index: 3 !important;
    }
    .carousel-item {
      transition: all 0.6s cubic-bezier(0.93, 0.05, 0.05, 0.93);
    }

    .carousel-control-next-icon {
      background-image: url("~@/assets/icons/home/icon_gallery-arrow.svg");
    }

    .carousel-control-prev-icon {
      background-image: url("~@/assets/icons/home/icon_gallery-arrow.svg");
      transform: rotate(180deg);
    }

    .carousel-control-prev,
    .carousel-control-next {
      opacity: 1;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      width: 27px;
      height: 49px;
    }

    .carousel-control-prev {
      left: -12%;
      @media (max-width: $xxl) {
        left: -14%;
      }
      @media (max-width: $xl) {
        left: -15%;
      }
      @media (max-width: $lg) {
        left: -17%;
      }
    }

    .carousel-control-next {
      right: -12%;
      @media (max-width: $xxl) {
        right: -14%;
      }
      @media (max-width: $xl) {
        right: -15%;
      }
      @media (max-width: $lg) {
        right: -17%;
      }
    }

    .carousel-indicators {
      bottom: -50px;
    }

    .carousel-indicators li {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin: 0 8px;
      opacity: 1;
      background-color: transparent;
      border: 1px solid #fff;
      outline: none;
    }

    .carousel-indicators li.active {
      background-color: #fff;
    }
  }
}
// video modal
#modal-2 {
  background-image: linear-gradient(
    269deg,
    rgba(0, 40, 140, 0.7) 1%,
    rgba(0, 10, 35, 0.7) 100%
  );
  .modal-dialog {
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 28px 0;
    width: 75vw;
    max-width: 90vw;
  }
  .modal-content {
    border-radius: 5px;
    background: #ffffff;
    border: 0;
  }
  .modal-body {
    padding: 0;
    width: 100%;
  }
  .modal-carousel-container {
    position: relative;
    width: 100%;
    height: auto;
    iframe {
      // width: 100%;
      width: 75vw;
      height: calc(75vw / 16 * 9);
    }
  }
  .modal__close {
    position: absolute;
    top: -10px;
    right: -80px;
    width: 58px;
    height: 58px;
    border: 1px solid #ffffff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 9;
    @media (max-width: $md) {
      height: 48px;
      width: 48px;
      right: -65px;
    }
    @media (max-width: $sm) {
      height: 32px;
      width: 32px;
      right: -48px;
    }
    @media (max-width: 450px) {
      height: 26px;
      width: 26px;
      right: -38px;
    }
    svg {
      outline: none;
      @media (max-width: $md) {
        width: 12px;
        height: 12px;
      }
      @media (max-width: $sm) {
        width: 10px;
        height: 10px;
      }
      @media (max-width: 450px) {
        width: 8px;
        height: 8px;
      }
    }
  }
  .modal-header {
    height: 10px;
    padding: 0;
    button {
      display: none;
    }
  }
  .modal-footer {
    height: 10px;
    padding: 0;
  }
  .btn {
    display: none;
  }
}
</style>